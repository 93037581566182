import "./App.css";
import myImage from "./Imagedd0058.png";
import myImage22 from "./sss.png";
import { React, useState, useEffect } from 'react';
import { Route, Routes, useNavigate } from "react-router-dom";

import { Row, Col, Card, Breadcrumb, Layout, Menu, theme, Grid, Button } from 'antd';

import { HomeOutlined, InfoCircleOutlined, PhoneOutlined} from '@ant-design/icons';

import POCETNA from "./Stranice/POCETNA";
import KONTAKT from './Stranice/KONTAKT';
import ONAMA from './Stranice/ONAMA';
const { Header, Content, Footer } = Layout;

/*
  <Row >
      
<Col span={12}  style={{ itemAlign: 'left',  padding: 0, margin: 0, backgroundColor: "yelow"}}>

      
</Col>
<Col span={12}  style={{ display: "flex" , itemAlign: 'right', textAlign: "right", padding: 0, margin: 0, backgroundColor: "red"}}>


      </Col>
</Row>
*/

const App = () => {
  
  const [selectedKey, setSelectedKey] = useState('/');

  const navigate = useNavigate()
  const handleMenuSelect = (key) => {
    setSelectedKey(key);
    navigate(key); // Промени URL
    // Ovde možete dodati kôd za preusmeravanje na stranicu na osnovu ključa, ako to već nemate
  };

  const handleLogoClick = () => {
    setSelectedKey('/');
    handleMenuSelect('/'); // Ako već imate preusmeravanje ovde, zadržite ga
  };


 


  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();


  return (
    <Layout  style={{ minHeight: '100vh', position: 'relative' }}> 
      <Header className="heder" style={{
        position: 'fixed', 
        top: 0, width: '100%',  
        backgroundColor: "#cdc7debb", 
        display: 'flex', 
        justifyContent: 'space-between', 
        zIndex: 1 }}>

        <div className="heder" style={{ fontSize: 26, fontWeight: "bold", color: '#000'}}>
        <img className="heder"
      src={myImage22}
      alt="Moja slika"
      style={{ width: '300px', cursor: 'pointer' }}
      onClick={() => handleMenuSelect('/')} // Navigacija na početnu stranu
    />
        </div>
        

        
        <div >
        <Menu className="heder"
          onClick={({ key }) => handleMenuSelect(key)}
          theme="dark"
          mode="horizontal"
          selectedKeys={[selectedKey]}
          style={{
            fontFamily: 'MojFont',
            border: 'none',
            background: 'none',
            width: '150%',
            margin: 0,
            padding: 0,
            flex: 1,
            fontWeight: 'bold',
            fontSize: '16px',
          }}
          items={[
            {
              key: '/',
              label: 'Početna',
            },
            {
              key: '/o_nama',
              label: 'O nama',
            },
            {
              key: '/kontakt',
              label: 'Kontakt',
            },
          ]}
        />
        <div className="slika1" style={{  position: "absolute", right: "30px", display: "flex", flexDirection: "column", alignItems: "flex-end"  }}>
          <Button style={{ margin: "10px", width: "50px", height: "50px" }} onClick={() => handleMenuSelect('/')} size="large" shape="circle" icon={<HomeOutlined />} />
          <Button style={{ margin: "10px", width: "50px", height: "50px" }} onClick={() => handleMenuSelect('/o_nama')} size="large" shape="circle" icon={<InfoCircleOutlined />} />
          <Button style={{ margin: "10px", width: "50px", height: "50px" }} onClick={() => handleMenuSelect('/kontakt')} size="large" shape="circle" icon={<PhoneOutlined />} />
        </div>
      </div>
      


    </Header>
    <Content>

      <div
        style={{
          background: colorBgContainer,
          minHeight: 280,
          padding: 24,
          borderRadius: borderRadiusLG,
        }}
      >
              
        <Routes>
          <Route path="/" element={<POCETNA/>}/>
          <Route path="/kontakt" element={<KONTAKT/>}/>
          <Route path="/o_nama" element={<ONAMA/>}/>
        </Routes>
      
      </div>
      <br/><br/><br/><br/><br/><br/>
    </Content>
    <Footer
      style={{ 
        position: 'absolute', 
        bottom: 0, 
        width: '100%', 
        textAlign: 'center', 
        backgroundColor: "#cdc7de" }}>
 
      <h2 class="h2">
        BORDO SAVIĆ | Svetog Save xx, 22230 Erdevik | PIB:112917833 MB:66441440 | 064/200-11-61 | 0637105384
      </h2>
    </Footer>
  </Layout>
);
};
export default App;