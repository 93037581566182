import "../App.css";
import { React, useState } from 'react';

import {  Divider, Typography, Space, Col, Row, Card, Input,
          Form, Button, Menu, List, Icon, message, FloatButton, AutoComplete } from 'antd';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useNavigate } from "react-router-dom"



const { Title, Text, Link } = Typography;




const KONTAKT = () => {

  //Покрени Модал кад се попуни форма
  const onFinish = (values) => {    

  };
  


 //За мејл
 const customStyle = {
  fontFamily: 'MojFont',
};
const [options2, setOptions2] = useState([]);
const [inputValue, setInputValue] = useState('');

const handleSearch = (value) => {
  setInputValue(value);
  let res = [];
  if (!value || value.indexOf('@') >= 0) {
    res = [];
  } else {
    res = ['ism-studio.rs', 'yandex.com', 'yahoo.com', 'vivaldi.net', 'gmail.com'].map((domain) => ({
      
      value: `${value}@${domain}`,
      style: customStyle,
    }));
  }
  setOptions2(res);
};
const onSelect2 = (value) => {
  setInputValue(value);
};




  
  const [form] = Form.useForm();
  const { Title, Text } = Typography;
  const navigate = useNavigate()

  const textAdresa = 'Svetog Save xx, Erdevik';
  const textMejl = 'xx';
  const textTelefon = 'xx';
  const textMB = 'xx';
  const textPIB = 'xx';
  const textPDV = 'xx';
  const textDI = 'xx';
  const textDE = 'xx';
  const textDir = 'xx';





//_________________
//Копирање текста
  const CopyButton = ({ text }) => {
    const handleCopy = () => {
      message.success(<span style={{ fontFamily: 'MojFont' }}>
      Текст је копиран.
    </span>);
    };
    return (
      <CopyToClipboard text={text}>
              <span type="text" block
        style={{ display: 'block', width: '100%',  padding: '8px',  cursor: 'pointer' }}
        onClick={handleCopy}
      >
        {text}
      </span>
      </CopyToClipboard>
    );
  };






  const data = [
    {
      title: 'Mejl',
      //Logo: 
      Opis: <CopyButton text={textMejl}/>,
    },
    {
      title: 'Telefon',
      //Logo: <CopyButton text={textTelefon} />,
      Opis: <CopyButton text={textTelefon} />,
    },
    {
      title: 'Adresa',
      //Logo: <CopyButton text={textAdresa} />,
      Opis: <CopyButton text={textAdresa} />,
      
    },
    {
      title: 'MB',
      //Logo: <CopyButton text={textMB} />,
      Opis: <CopyButton text={textMB} />,
    },
    {
      title: 'PIB',
      //Logo: <CopyButton text={textPIB} />,
      Opis: <CopyButton text={textPIB} />,
    },
    {
      title: <CopyButton text={textPDV} />,
      //Logo: <CopyButton text={textPDV} />,
    },
    {
      title: 'Dinarski račun',
      //Logo: <CopyButton text={textDI} />,
      Opis: <CopyButton text={textDI} />,
    },
    {
      title: 'Devizni račun',
      //Logo: <CopyButton text={textDE} />,
      Opis: <CopyButton text={textDE} />,
    },
    {
      title: 'Vlasnik i direktor',
      //Logo: <CopyButton text={textDir} />,
      Opis: <CopyButton text={textDir} />,
    },


  ];

//_________________________________________________________________________________________________
return <div>

  
  
  <div className="slika"> <br/> <br/> <br/> </div>

  <h1 className="h1">Kontakt</h1>
 <br/> <br/> <br/> 
 
  <Row className="kontakt1">
    
    <Col span={12} className="h2" style={{ textAlign: 'right',  padding: 4, margin: 0}}
    >
    Adresa: <br/>
    <br/>
    Mejl: <br/>
    Telefon:<br/>
    MB: <br/>
    PIB: <br/>
    Račun: <br/>
    
    <br/><br/>
    </Col>

    <Col span={12} className="h2" style={{ textAlign: 'left', padding: 4, margin: 0}}>
    Svetog Save xx<br/>
    22230 Erdevik<br/>
    info@yxcvyyfdv.rs<br/>
    +3816422333<br/>
    1111111<br/>
    222222<br/>
    222-22222222-22<br/>
    
    <br/><br/>
    </Col>
    
  </Row>
  
    



<div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
    <div  className="kont1" style={{ width: '30%', paddingRight: '2%' }}>
        <List
            itemLayout="horizontal"
            dataSource={data}
            className="kont1"
            style={{ justifyContent: 'center', justifyItems: 'center', textJustify: 'center' }}
            renderItem={(item) => (
                <List.Item style={{ 
                  display: 'flex', 
                  fontFamily: 'MojFont', 
                  textAlign: 'center',  
                  marginLeft: 80, 
                  marginRight: 80,
                  fontSize: '22px',
                  width: '200px',
                }}>
                    <List.Item.Meta
                        avatar={item.Logo}
                        title={item.title}
                        description={item.Opis}
                    />
                </List.Item>
            )}
        />
    </div>

    <div style={{ width: '70%', maxWidth: "400px", justifyContent: 'center' }}>
        <Form
            name="contact-us"
            onFinish={onFinish}
            form={form}
            layout="vertical"
            className="custom-form-item"
            
        >
<Form.Item //______________________________________________________________________Име
          label="Ime"
          className="custom-form-item"  
          name="firstName"
          required
          rules={[
            {
              required: true,
              message: false,
            },
          ]}>

          <Input 
            style={{ fontFamily: 'MojFont' }}/>
        </Form.Item> 


        <Form.Item //______________________________________________________________________Презиме
          style={{fontFamily: 'MojFont'}} 
          className="custom-form-item"  
          label="Prezime"
          name="lastName"
          required
          rules={[
            {
              required: true,
              message: false,
            },
          ]}>

          <Input 
          style={{ fontFamily: 'MojFont' }}/>
        </Form.Item>

        <Form.Item //______________________________________________________________________Мејл
          style={{fontFamily: 'MojFont'}}
          className="custom-form-item"  
          label="Mejl"
          
          name="email"
          required
          labelAlign="top"
          
          rules={[
            {
              required: true,
              message: false,
              type: "email",
            },
            {
            validator: (_, value) => {
              if (!value || !/^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/i.test(value)) {
                return Promise.reject('');
              }
              return Promise.resolve();
            },
          },
          ]}
        >
          <AutoComplete
            style={{fontFamily: 'MojFont'}}
            onSearch={handleSearch}
            options={options2}
          >
            <Input/>
          </AutoComplete>
        </Form.Item>

        <Form.Item //______________________________________________________________________Текст
          style={{fontFamily: 'MojFont'}}
          className="custom-form-item"  
          label="Tekst..."
          name="message"
          required
          rules={[
            {
              required: true,
              message: "Унесите поводом чега нас контактирате...",
            },
          ]}>

          <Input.TextArea 
            style={{
              fontFamily: 'MojFont'}}
            autoSize={{ minRows: 4, maxRows: 6 }}/>

        </Form.Item>

        <Form.Item //______________________________________________________________________Пошаљи
          className="custom-form-item"  >
          <Button 

            htmlType="submit" 
            style={{ fontFamily: 'MojFont' }}>
              Pošalji
          </Button>
        </Form.Item>
        </Form>
    </div>
</div>
     

         
<div className="kontakt1"> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> </div>

       

  <FloatButton.BackTop style={{width: "45px", height: "45px"}}/>
</div>;
};

export default KONTAKT;