import "../App.css";
import myImage from "../Image00581.png";
import myImage1 from "../1.png";
import myImage2 from "../2.png";
import myImage3 from "../3.png";
import myImage4 from "../88.png";

import myImage10 from "../10.png";
import myImage11 from "../11.png";
import myImage12 from "../12.png";
import myImage13 from "../13.png";
import myImage14 from "../14.png";
import myImage15 from "../15.png";
import myImage16 from "../16.png";
import myImage17 from "../17.png";
import myImage18 from "../18.png";

import myImage19 from "../56.png";
import myImage20 from "../57.png";

import myImage0 from "../slika.png";

import { React, useState } from 'react';
import {  Typography, Space, Button, Carousel, Card, Image, FloatButton, Row, Col } from 'antd';

const { Title, Text, Link } = Typography;


const contentStyle = {
  height: '160px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#364d79',
};

//_________________________________________________________________________________________________
const POCETNA = () => {

  

 

  // Definišite tri kolekcije slika
  const imagesSet1 = [
    myImage10, myImage11, myImage12
  ];

  const imagesSet2 = [
    myImage13, myImage14, myImage15
  ];

  const imagesSet3 = [
    myImage16, myImage17, myImage18
  ];

  // Koristite state za praćenje trenutne kolekcije slika
  const [currentImages, setCurrentImages] = useState(imagesSet1);

  // Funkcije za promenu trenutne kolekcije slika
  const handleButtonClick1 = () => {
    setCurrentImages(imagesSet1);
  };

  const handleButtonClick2 = () => {
    setCurrentImages(imagesSet2);
  };

  const handleButtonClick3 = () => {
    setCurrentImages(imagesSet3);
  };



  const [current, setCurrent] = useState(0);
  const onChange = (value) => {
    console.log('onChange:', value);
    setCurrent(value);
  };
 

//_________________________________________________________________________________________________
  return <div>





<div className="slika"> <br/> <br/> </div>




<img //______________________________________________________________________Слика
   className="slika"   style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        justifyItems: 'center', 
        width: '107%', 
        marginLeft: "-3.5%",

        fontFamily: 'MojFont',

      }} 
      src={myImage} alt="Moja slika"
    />
<img //______________________________________________________________________Слика
   className="slika1"   style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        justifyItems: 'center', 
        width: '110%', 
        marginLeft: "-5%",

        fontFamily: 'MojFont',

      }} 
      src={myImage0} alt="Moja slika"
    />


<br/>

    <h1 //______________________________________________________________________Од идеје
    className="h1">GRAĐEVINSKI MATERIJALI I ALATI</h1>
        <h2 //______________________________________________________________________Од идеје
    className="h1">Sve što je potrebno za kuću i građu</h2>

<div className="slika" style={{ padding: '20px' }}>
  <Row gutter={[16, 16]} justify="center">
    <Col xs={24} sm={12} md={8}>
      <Card style={{ textAlign: 'center' }}>
        <img src={myImage1} alt="Moja slika" style={{ width: '100px' }} />
        <h1 className="h1">GRAĐEVINSKI MATERIJAL</h1>
        <h2 className="h2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.</h2>
      </Card>
    </Col>
    <Col xs={24} sm={12} md={8}>
      <Card style={{ textAlign: 'center' }}>
        <img src={myImage2} alt="Moja slika" style={{ width: '100px' }} />
        <h1 className="h1">VODOVOD I KANALIZACIJA</h1>
        <h2 className="h2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.</h2>
      </Card>
    </Col>
    <Col xs={24} sm={12} md={8}>
      <Card style={{ textAlign: 'center' }}>
        <img src={myImage3} alt="Moja slika" style={{ width: '100px' }} />
        <h1 className="h1">ALATI I OPREMA</h1>
        <h2 className="h2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.</h2>
      </Card>
    </Col>
  </Row>
</div>

<div className="slika1" style={{ padding: '20px' }}>
  
      <Card style={{ textAlign: 'center', margin: "10px" }}>
        <img src={myImage1} alt="Moja slika" style={{ width: '100px' }} />
        <h1 className="h1">GRAĐEVINSKI MATERIJAL</h1>
        <h2 className="h2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.</h2>
      </Card>

      <Card style={{ textAlign: 'center', margin: "10px" }}>
        <img src={myImage2} alt="Moja slika" style={{ width: '100px' }} />
        <h1 className="h1">VODOVOD I KANALIZACIJA</h1>
        <h2 className="h2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.</h2>
      </Card>

      <Card style={{ textAlign: 'center', margin: "10px" }}>
        <img src={myImage3} alt="Moja slika" style={{ width: '100px' }} />
        <h1 className="h1">ALATI I OPREMA</h1>
        <h2 className="h2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.</h2>
      </Card>

</div>

   
    <Card style={{ 
      display: 'block', 
      marginLeft: "auto",  
      marginRight: "auto", 
      width: "100%", 
      maxWidth: "800px", 
      justifyContent: 'center', 
      justifyItems: 'center' }}>

      <div style={{ textAlign: 'center', marginBottom: '16px'}}>
        <Button style={{marginLeft: "2%", maxWidth: "250px", width: "20%", minWidth: "170px", marginRight: "1%", border: "5"}} onClick={handleButtonClick1}>Građevinski materijal</Button>
        <Button style={{marginLeft: "2%", maxWidth: "250px", width: "20%", minWidth: "170px", marginRight: "2%", border: "5"}} onClick={handleButtonClick2}>Vodovod i kanalizacija</Button>
        <Button style={{marginLeft: "2%", maxWidth: "250px", width: "20%", minWidth: "170px", marginRight: "1%", border: "5"}} onClick={handleButtonClick3}>Alati i oprema</Button>
      </div>

      <Card >
        <Carousel autoplay autoplaySpeed={1500}>
          {currentImages.map((image, index) => (
            <div key={index}>
              <img src={image} alt={`Slika ${index + 1}`} style={{ width: '100%' }} />
            </div>
          ))}
        </Carousel>
      </Card>
    </Card>

   

    <div style={{ position: 'relative', width: '100%' }}>
  {/* Prva slika kao pozadina */}
  <img
    src={myImage4}
    alt="Moja slika"
    style={{         width: '107%', 
    marginLeft: "-3.5%", 
    height: 'auto' }}
  />

  {/* Div koji sadrži druge dve slike */}
  <div
    style={{
      position: 'absolute',
      top: '60%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      display: 'flex',
      justifyContent: 'center',
      width: '90%',
    }}
  >
    {/* Druga slika */}
    <img
      src={myImage19}
      alt="Moja slika 2"
      style={{ width: '50%', height: 'auto', marginRight: '10px' }}
    />

    {/* Treća slika */}
    <img
      src={myImage20}
      alt="Moja slika 3"
      style={{ width: '50%', height: 'auto' }}
    />
  </div>
</div>


    <br/><br/><br/>

    <FloatButton.BackTop style={{width: "45px", height: "45px"}}  />

  </div>
  
};

export default POCETNA;