
import "../App.css";
import myImage1 from "../1.png";
import myImage2 from "../22.png";

import React, { useState } from 'react';



import {  Divider, Typography, Space, Col, Row, Card, Input,
          Form, Button, Menu, List, Icon, message, FloatButton, AutoComplete } from 'antd';

import { LinkedinOutlined, DashboardOutlined} from '@ant-design/icons';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useNavigate } from "react-router-dom"


//_________________________________________________________________________________________________

const { Title, Text, Link } = Typography;


//_________________________________________________________________________________________________




const ONAMA = () => {

  
//_________________________________________________________________________________________________
  return <div>
<div className="slika"> <br/> <br/> <br/> </div>

<h1 className="h1">O nama</h1>
 <br/> <br/> <br/>

<h2 class="h2">Aleksandar Savić PR</h2>
<h2 class="h2">trgovinska radnja</h2>
<h2 class="h2">BORDO SAVIĆ</h2>
<h2 class="h2">Svetog Save xx, 22230 Erdevik</h2>
<h2 class="h2">PIB:112917833 MB:66441440</h2>
<h2 class="h2">Telefon: 064/200-11-61</h2>
<h2 class="h2">Alternativni telefon:0637105384</h2>

<h2 class="h2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.</h2>
        
   <br />   
      
<h1 className="h1">Naš tim</h1>

        
<div className="slika" style={{ padding: '20px' }}>
  <Row gutter={[16, 16]} justify="center">
    <Col xs={24} sm={12} md={8}>
      <Card style={{ textAlign: 'center' }}>
        <img src={myImage2} alt="Moja slika" style={{ width: '100px' }} />
        <h1 className="h1">Aleksandar Savić</h1>
        <h2 className="h2">Direktor i vlasnik</h2>
      </Card>
    </Col>
    <Col xs={24} sm={12} md={8}>
      <Card style={{ textAlign: 'center' }}>
        <img src={myImage2} alt="Moja slika" style={{ width: '100px' }} />
        <h1 className="h1">Ilija Savić</h1>
        <h2 className="h2">Zamenik direktora</h2>
      </Card>
    </Col>
  </Row>
</div>

<div className="slika1" style={{ padding: '20px'}}>
      <Card style={{ textAlign: 'center', margin: "10px" }}>
        <img src={myImage2} alt="Moja slika" style={{ width: '100px' }} />
        <h1 className="h1">Aleksandar Savić</h1>
        <h2 className="h2">Direktor i vlasnik</h2>
      </Card>
 
      <Card style={{ textAlign: 'center', margin: "10px"  }}>
        <img src={myImage2} alt="Moja slika" style={{ width: '100px' }} />
        <h1 className="h1">Ilija Savić</h1>
        <h2 className="h2">Zamenik direktora</h2>
      </Card>
  
</div>     
          
<div className="kontakt1"> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> <br/> </div>


    <FloatButton.BackTop style={{width: "45px", height: "45px"}} />
  </div>;
};

export default ONAMA;